import shuffle from 'lodash-es/shuffle';
import store from '@/store';

import QuizSession from './quiz-session';
import DataProvider from './data-provider';

export default class MultipleMatchController {
    constructor({ items, question }, parent) {
        this.type = 'multipleMatch';
        this.items = items;
        this.question = question;
        this.parent = parent;

        this.correctCount = 0;
        this.totalCount = this.items.length + this.items.filter(x => x.subs).reduce((a, b) => a + b.subs.length, 0);
        // this.weight = this.items.length
        this.progress = 0;
        this.score = 0;
        this.failCount = 0;

        this.state = {};

        this.foobarMap = {};
        this.items.forEach((x, i) => {
            this.foobarMap[`target-${i}`] = x;
            this.foobarMap[`source-${i}`] = x;

            this.state[`target-${i}`] = null;
        });

        this.failedStuff = new Set();
    }
    getQuestion() {
        return this.question;
    }
    getTargetItems() {
        const shouldShuffle = !store.state.moduleApp.debugSettings.useQuizCheatMode;

        const items = this.items.map((x, i) => {
            return {
                id: `target-${i}`,
                label: x.target,
                image: x.image
            };
        });
        return shouldShuffle ? shuffle(items) : items;
    }
    getShelfItems() {
        const shouldShuffle = !store.state.moduleApp.debugSettings.useQuizCheatMode;

        const items = this.items.map((x, i) => {
            return {
                id: `source-${i}`,
                label: x.source,
                image: x.image
            };
        });
        return shouldShuffle ? shuffle(items) : items;
    }

    updateCorrectCount() {
        this.correctCount = 0;
        Object.entries(this.state).forEach(([targetId, sourceId]) => {
            if (!sourceId) {
                return;
            }
            const targetItem = this.items.find(item => item.target === targetId) || this.foobarMap[targetId];
            this.correctCount += 1 + (targetItem.subs || []).length;
        });
        if (this.subsession) {
            this.correctCount -= this.subsession.totalCount - this.subsession.correctCount;
        }
        // this.items.forEach(item => {
        //   if ()
        // })
        // Object.values(this.state)
        // this.correctCount = Object.keys(this.state).length
        // + session.correctCount
        this.progress = this.correctCount / this.totalCount;
        this.score = this.progress;
        this.parent && this.parent.update();
    }

    getSummary() {
        const completedItems = [];
        const failedItems = [];
        this.items.forEach((item, i) => {
            const v = this.state[`target-${i}`];
            if (v) {
                completedItems.push({
                    id: item.itemId,
                    source: item.itemSource
                });
            } else if (this.failedStuff.has(item.itemId)) {
                failedItems.push({
                    id: item.itemId,
                    source: item.itemSource
                });
            }
        });

        return {
            completedItems,
            failedItems
        };
    }

    match(targetId, sourceId) {
        // NOTE: Support for both ids and labels at the moment
        let item = this.items.find(item => item.target === targetId);
        let didMatch = false;
        if (item) {
            didMatch = item.source === sourceId;
        } else {
            item = this.foobarMap[targetId];
            didMatch = item === this.foobarMap[sourceId];
        }

        // const didMatch = item.source === sourceId
        if (didMatch) {
            // this.correctCount += 1
            this.state[targetId] = sourceId;

            if (item.subs) {
                const dataProvider = new DataProvider(item.subs);
                const session = new QuizSession(dataProvider, { lives: 1 });
                session.on('update', () => {
                    this.updateCorrectCount();
                    // console.log('subsession update', session.correctCount, Object.keys(this.state).length)
                    // this.correctCount = Object.keys(this.state).length + session.correctCount
                    // this.parent.foobar()
                });
                session.on('end', () => {
                    // console.log('subsession end', session.correctCount, session.totalCount)
                    if (session.correctCount < session.totalCount) {
                        this.state[targetId] = false;
                        this.failCount += 1;
                        this.failedStuff.add(this.foobarMap[sourceId].itemId);
                        this.failedStuff.add(this.foobarMap[targetId].itemId);
                    }
                    this.subsession = null;
                    this.updateCorrectCount();
                    // this.state[targetId] = sourceId
                    // this.progress = Object.keys(this.state).length / this.totalCount
                    // this.score = this.progress
                    // this.parent.foobar()
                });
                this.subsession = session;
            } else {
                // this.state[targetId] = sourceId
                // this.progress = Object.keys(this.state).length / this.totalCount
                // this.score = this.progress
            }
            this.updateCorrectCount();
        } else {
            this.failCount += 1;
            this.state[targetId] = false;
            this.failedStuff.add(this.foobarMap[sourceId].itemId);
            this.failedStuff.add(this.foobarMap[targetId].itemId);
            // this.parent && this.parent.looseLife()
        }
        this.parent && this.parent.update();
        return didMatch;
    }
}
