<template>
  <div class="SortedItem">
    <MnemonicImage
      class="image"
      :image="image" />
    <div class="checkmark">
      <svgicon icon="checkmark" />
    </div>
  </div>
</template>

<script>
import MnemonicImage from '@/components/MnemonicImage';

export default {
    components: { MnemonicImage },
    props: {
        image: {
            type: String
        }
    }
};
</script>

<style lang="scss" scoped>
.SortedItem {
    position: relative;
}
.image {
    border-radius: 4px;
}
.checkmark {
    $size: 1em;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: black;
    position: absolute;
    top: -$size / 2;
    right: -$size / 2;

    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;

    animation: grow 1s;

    @keyframes grow {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }

    .svg-icon {
        $size: 50%;
        width: $size;
        height: $size;
        fill: white;
    }
}
</style>
