import shuffle from 'lodash-es/shuffle';
import store from '@/store';

export default class PickInSequenceController {
    constructor({ items, question, text, preselect, renderer }, parent) {
        this.type = 'pickInSequence';
        this.renderer = renderer;
        this.question = question;
        this.text = text;
        this.parent = parent;

        this.unskippable = true;
        this.correctCount = 0;
        this.totalCount = 1;
        this.progress = 0;
        this.score = 0;
        this.failCount = 0;

        this.state = [];
        this.fasit = [];

        items.map((x, i) => {
            if (x.range) {
                this.fasit.push(`item-${i}`);
            }
        });

        items = items.map((x, i) => {
            return {
                id: `item-${i}`,
                range: x.range,
                image: x.image
            };
        });

        const shouldShuffle = !store.state.moduleApp.debugSettings.useQuizCheatMode;

        this.items = shouldShuffle ? shuffle(items) : items;

        if (preselect) {
            for (let i = 0; i < preselect; i++) {
                this.pick(this.fasit[i]);
            }
        }
    }
    getQuestion() {
        return this.question;
    }
    getText() {
        return this.text;
    }
    getItems() {
        return this.items;
    }

    updateCorrectCount() {
        this.correctCount = this.fasit.length === this.state.length ? 1 : 0;
        this.progress = this.correctCount / this.totalCount;
        this.score = this.progress;
        this.parent && this.parent.update();
    }

    pick(itemId) {
        const isCorrect = this.fasit[this.state.length] === itemId;
        if (isCorrect) {
            this.state.push(itemId);
            this.updateCorrectCount();
        } else {
            this.failCount += 1;
        }
        this.parent && this.parent.update();
        return isCorrect;
    }
}
