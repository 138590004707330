import MultipleChoiceController from './multiple-choice-controller';
import MultipleMatchController from './multiple-match-controller';
import MultiMultipleChoiceController from './multi-multiple-choice-controller';
import FallingMatchController from './falling-match-controller';
import PickInSequenceController from './pick-in-sequence-controller';
import WhenQuestionWithNumberInputController from './when-question-with-number-input-controller';

const controllerClassMap = {
  multipleChoice: MultipleChoiceController,
  multipleMatch: MultipleMatchController,
  multiMultipleChoice: MultiMultipleChoiceController,
  fallingMatch: FallingMatchController,
  pickInSequence: PickInSequenceController,
  whenQuestionWithNumberInput: WhenQuestionWithNumberInputController
};


export default class QuizSession {
  constructor(dataProvider, { lives = -1, requeue = true }) {
        this.lives = lives;
        this.progress = 0;
        this.correctCount = 0;
        this.currentController = null;
        this.initialLives = lives;
        this.requeueIncorrect = requeue;

        this.eventListeners = [];
        this.controllers = dataProvider.items.map(item => {
            const ControllerClass = controllerClassMap[item.type];
            return new ControllerClass(item, this);
        });

        this.totalCount = this.controllers.reduce((acc, c) => {
          return acc + c.totalCount;
        }, 0);

        this.queue = this.controllers.slice();
    }

    on(event, func) {
        this.eventListeners.push([event, func]);
    }

    off(event, func) {
        if (!event) {
            this.eventListeners = [];
        } else if (!func) {
            this.eventListeners = this.eventListeners.filter(([e, f]) => {
                return f !== func;
            });
        } else {
            this.eventListeners = this.eventListeners.filter(([e, f]) => {
                return e !== event && f !== func;
            });
        }
    }

    emit(eventType) {
        this.eventListeners.forEach(([eType, func]) => {
            if (eType === eventType) {
                func();
            }
        });
    }

    update() {
        const controllers = this.controllers || [];
        this.correctCount = 0;
        this.totalCount = 0;
        this.progress = 0;
        this.lives = this.initialLives;
        controllers.forEach(c => {
            this.correctCount += c.correctCount;
            this.totalCount += c.totalCount;
            this.progress += c.progress;
            this.lives -= c.failCount;
        });
        this.progress = this.progress / controllers.length;
        this.emit('update');
    }

    next() {
      if (this.lives === 0) {
        this.currentController = null;
        this.emit('end');
        return null;
      }

      if (this.currentController) {
        if (mustAnswerCorrectly(this.currentController)) {
          this.askAgain(this.currentController);
        }
        if (this.shouldRequeue(this.currentController)) {
          this.requeue(this.currentController);
        }
      }

      this.currentController = this.queue.shift() || null;

      if (!this.currentController) {
        this.emit('end');
      }
      return this.currentController;
    }

    shouldRequeue(c) {
      return (!answeredCorrectly(c) && this.requeueIncorrect)
    }

    getSummary() {
        const completedItems = [];
        const failedItems = [];

        this.controllers.forEach(c => {
            if (c.getSummary) {
                const summary = c.getSummary();
                completedItems.push(...summary.completedItems);
                failedItems.push(...summary.failedItems);
            }
        });

        return {
            success: this.totalCount === this.correctCount,
            totalCount: this.totalCount,
            correctCount: this.correctCount,
            completedItems,
            failedItems
        };
    }

  requeue(c) {
    c.backInQueue = (c.backInQueue || 0) + 1;
    if (c.shuffle) {
      c.shuffle();
    }
    this.queue.push(c);
  }

  askAgain(c) {
    this.queue.splice(0, 0, this.currentController)
  }
}

function answeredCorrectly(c) {
  return c.score > 0;
}

function mustAnswerCorrectly(c) {
  return (
    (c.unskippable || c.totalCount > 1) &&
    c.progress < 1
  )
}
