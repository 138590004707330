<template>
  <div class="MultipleMatchPure">
    <div
      v-if="question"
      class="question">
      {{ question }}
    </div>
    <SourceTargetInteraction
      :target-ids="targetIds"
      :source-ids="sourceIds"
      :check-match="checkMatch"
      :use-internal-matched-state="false"
      source-selector=".ShelfItem"
      target-selector=".TargetItem">
      <template slot-scope="{ sourceItems: sItems, targetItems: tItems }">
        <div class="grid">
          <TargetItem
            v-for="targetItem of tItems"
            :key="targetItem.id"
            :data-id="targetItem.id"
            :data="targetItemsMap[targetItem.id]"
            :other="shelfItemsMap[state[targetItem.id]]"
            :matched="!!state[targetItem.id]"
            v-bind="targetItem"
            class="TargetItem">
            {{ targetItemsMap[targetItem.id].label }}
          </TargetItem>
        </div>

        <Shelf
          class="shelf"
          :items="sItems"
          :used-state="inverseStateMap">
          <template
            slot="shelfItem"
            slot-scope="sourceItem">
            <ShelfItemWithImage
              class="ShelfItem"
              :state="sourceItem"
              v-bind="shelfItemsMap[sourceItem.id]"
              :data-id="sourceItem.id" />
          </template>
        </Shelf>
      </template>

      <template
        slot="dragItem"
        slot-scope="props">
        <ShelfItemWithImage
          class="dragItem"
          :state="{}"
          v-bind="shelfItemsMap[props.id]" />
      </template>
    </SourceTargetInteraction>
  </div>
</template>

<script>
import soundService from '@/services/sound-service';
import correctSound from '@/assets/sounds/correct-answer.mp3';

import SourceTargetInteraction from '@/modules/games-shared/components/SourceTargetInteraction';
import Shelf from '@/modules/games-shared/components/Shelf';
import ShelfItemWithImage from '@/modules/games-shared/components/ShelfItemWithImage';
import TargetItem from './MatchTargetItem';

export default {
    components: { SourceTargetInteraction, TargetItem, ShelfItemWithImage, Shelf },
    props: {
        question: {
            type: String
        },
        targetItems: {
            type: Array,
            required: true
        },
        shelfItems: {
            type: Array,
            required: true
        },
        match: {
            type: Function
        },
        state: {
            type: Object
        }
    },
    computed: {
        inverseStateMap() {
            const inverseStateMap = {};
            Object.values(this.state).forEach(sourceId => {
                inverseStateMap[sourceId] = true;
            });
            return inverseStateMap;
        },
        targetIds() {
            return this.targetItems.map(item => item.id);
        },
        sourceIds() {
            return this.shelfItems.map(item => item.id);
        },
        targetItemsMap() {
            const map = {};
            this.targetItems.forEach(targetItem => {
                map[targetItem.id] = targetItem;
            });
            return map;
        },
        shelfItemsMap() {
            const map = {};
            this.shelfItems.forEach(item => {
                map[item.id] = item;
            });
            return map;
        }
    },
    created() {
        this.foobarCounter = 0;
    },
    methods: {
        checkMatch(sourceId, targetId) {
            const didMatch = this.match(targetId, sourceId);

            if (didMatch) {
                this.foobarCounter += 1;
                soundService.play(correctSound);
            }
            // if (this.foobarCounter === this.targetItems.length) {
            // console.log('COMPLETED')
            setTimeout(() => {
                this.$emit('done');
            }, 1000);
            // }
            return didMatch;
        },
        playFeedback() {
            // this.controller.next()
        }
    }
};
</script>

<style lang="scss" scoped>
.MultipleMatchPure {
    color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.question {
    font-size: 140%;
    line-height: 1.5em;
    text-align: center;
}
.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;

    margin-top: 2em;

    flex: 1;
    overflow-y: auto;
}

.TargetItem {
    margin: 0.5em;
}

.shelf {
    align-self: stretch;
    margin-top: 1em;
}

// .shelfItems {
//   display: grid;
//   grid-column-gap: 1em;
//   grid-row-gap: 1em;
//   grid-template-columns: repeat(auto-fill, minmax(18em, 1fr));
// }

.dragItem {
    opacity: 0.5;
    transform: scale(1.3);
}
</style>
