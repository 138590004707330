<template>
  <div class="MultiMultipleChoicePure">
    <div class="question">
      {{ question }}
    </div>
    <div class="spacer" />
    <div class="choices">
      <ChoiceItem
        v-for="(choice, i) of choices"
        :key="i"
        v-bind="choice"
        :data-id="choice.id"
        :state="{ selected: state[choice.id], correct: state[choice.id] }"
        class="Option"
        @click="pickedChoice(choice.id)" />
    </div>

    <div
      v-if="finished"
      class="blockingLayer" />
  </div>
</template>

<script>
import { TimelineMax, Power2 } from 'gsap';
import soundService from '@/services/sound-service';
import correctSound from '@/assets/sounds/correct-answer.mp3';

import ChoiceItem from './ChoiceItem';

export default {
    components: { ChoiceItem },
    props: {
        question: {
            type: String,
            required: true
        },
        choices: {
            type: Array,
            required: true
        },
        select: {
            type: Function
        },
        state: {
            type: Object
        }
    },
    data() {
        return {
            finished: false
        };
    },
    created() {
        this.useGridLayout = this.choices.every(x => x && x.image);
    },
    methods: {
        async pickedChoice(choiceId) {
            const correct = this.select(choiceId);
            await this.playFeedback(choiceId, correct);
            this.$emit('done');
        },
        async playFeedback(choiceId, correct) {
            if (correct) {
                await this.feedbackOnCorrect(choiceId);
            } else {
                await this.feedbackOnWrong(choiceId);
            }
        },

        feedbackOnCorrect(choiceId) {
            soundService.play(correctSound);

            return new Promise(resolve => {
                const selectedChoiceElement = this.$el.querySelector(`.ChoiceItem[data-id="${choiceId}"]`);

                let tl = (this.tl = new TimelineMax({ onComplete: () => resolve() }));
                tl.to(selectedChoiceElement, 0.2, {
                    scale: 1.1
                });
                tl.to(selectedChoiceElement, 0.2, {
                    ease: Power2.easeInOut,
                    scale: 1
                });
                tl.to(selectedChoiceElement, 1, {});
            });
        },

        feedbackOnWrong(choiceId) {
            return new Promise(resolve => {
                const shakeSpeed = 0.1;

                const selectedChoiceElement = this.$el.querySelector(`.ChoiceItem[data-id="${choiceId}"]`);

                let tl = (this.tl = new TimelineMax({ onComplete: () => resolve() }));
                tl.to(selectedChoiceElement, shakeSpeed, {
                    x: -7,
                    ease: Power2.easeInOut
                });
                tl.to(selectedChoiceElement, shakeSpeed, {
                    repeat: 4,
                    x: 5,
                    yoyo: true,
                    ease: Power2.easeInOut
                });
                tl.to(selectedChoiceElement, shakeSpeed, {
                    x: 0
                });
                tl.to(selectedChoiceElement, 2, {});
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.MultiMultipleChoicePure {
    position: relative;
    text-align: center;

    display: flex;
    flex-direction: column;
    color: rgba(white, 0.9);
    overflow-y: auto;
}
.question {
    font-size: 140%;
    line-height: 1.5em;
}
.spacer {
    min-height: 2em;
    flex: 1;
}
.choices {
    display: grid;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    grid-template-columns: repeat(auto-fill, minmax(9em, 1fr));
}

.blockingLayer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
</style>
