<template>
  <div
    :class="[
      `ChoiceItem--${image ? 'image' : 'text'}`,
      { selected: state.selected, correct: state.correct, '--global-clickable': !state.selected }
    ]"
    :style="{ backgroundImage: image ? `url(${image})` : null }"
    class="ChoiceItem"
    @click="!state.selected && $emit('click')">
    <span v-if="!image">{{ text }}</span>
  </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String
        },
        image: {
            type: String
        },
        state: {
            type: Object
        }
    }
};
</script>

<style lang="scss" scoped>
$correct: #93c60b;
$wrong: #cd0a10;

.ChoiceItem {
    background-color: white;
    border-radius: 2px;

    &.selected {
        background-color: $wrong;
        color: white;
    }
    &.selected.correct {
        background-color: $correct;
        color: white;
    }
}

.ChoiceItem--text {
    color: rgba(black, 0.9);
    padding: 1em;
    line-height: normal;
}
.ChoiceItem--image {
    $size: 40%;
    width: $size;
    margin: 0.5em;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &::after {
        content: '';
        display: block;
        padding-top: 100%;
    }
}
</style>
