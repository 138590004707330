import shuffle from 'lodash-es/shuffle';
import store from '@/store';

export default class MultipleChoiceController {
    constructor({ question, options, itemSource, itemId }, parent) {
        this.type = 'multipleChoice';
        this.parent = parent;
        this.score = 0;
        this.progress = 0;
        this.totalCount = 1;
        this.correctCount = 0;
        this.failCount = 0;

        this.question = question;
        this.itemSource = itemSource;
        this.itemId = itemId;
        this._submittedAnswer = '';

        this.choices = options.map((x, i) => {
            if (typeof x === 'string') {
                x = { text: x };
            }
            return Object.assign({ id: `choice-${i + 1}` }, x);
        });
        this.fasit = this.choices[0].id;
        this.shuffle();
    }

    shuffle() {
        const shouldShuffle = !store.state.moduleApp.debugSettings.useQuizCheatMode;
        if (shouldShuffle) {
            this.choices = shuffle(this.choices);
        }
    }

    getQuestion() {
        return this.question;
    }

    getFasit() {
        return this.fasit;
    }

    getChoices() {
        return this.choices;
    }

    select(choiceId) {
        const isCorrect = this.fasit === choiceId;
        this.progress = 1;
        if (isCorrect) {
            this.score = 1;
            this.correctCount = 1;
        } else {
            this.score = 0;
            this.failCount += 1;
            this._submittedAnswer = this.choices.find(x => x.id === choiceId).text;
            // this.parent && this.parent.looseLife()
        }
        this.parent && this.parent.update();
        return isCorrect;
    }

    getIdFromText(text) {
        // Used for unit-tests
        return this.choices.find(x => x.text === text).id;
    }

    getSummary() {
        const completedItems = [];
        const failedItems = [];
        if (this.score === 1) {
            completedItems.push({
                id: this.itemId,
                source: this.itemSource
            });
        } else if (this.failCount && this.score < 1) {
            failedItems.push({
                id: this.itemId,
                source: this.itemSource,
                answer: this._submittedAnswer
            });
        }
        return {
            completedItems,
            failedItems
        };
    }
}
