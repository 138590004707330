<template>
  <div class="PickInSequence">
    <div class="question">
      {{ question }}
    </div>
    <div class="spacer" />

    <div
      class="summaryContainer"
      :class="{ 'show-tail': completed }"
      v-html="textWithMarking" />

    <transition-group
      class="sortedItems"
      name="list"
      tag="div">
      <SortedItem
        v-for="item of sortedItems"
        :key="item.id"
        class="SortedItem"
        :image="item.image" />
    </transition-group>

    <div class="availableItems">
      <UnsortedItem
        v-for="item of shuffledItems"
        :key="item.id"
        class="Item"
        :image="item.image"
        :attempts="item.attempts"
        @click="pickItem(item.id)" />
    </div>

    <div
      v-if="completed"
      class="blockingLayer" />
  </div>
</template>

<script>
import soundService from '@/services/sound-service';
import correctSound from '@/assets/sounds/correct-answer.mp3';
import UnsortedItem from './UnsortedItem';
import SortedItem from './SortedItem';

import markTextRange from './mark-text-range';

export default {
    components: { UnsortedItem, SortedItem },
    props: {
        question: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        items: {
            type: Array
        },
        state: {
            type: Array
        },
        pick: {
            type: Function
        }
    },
    data() {
        let shuffledItems = this.items.map((item, i) => {
            return {
                image: item.image,
                range: item.range,
                id: item.id,
                order: i,
                rotation: Math.random() * 20 - 10,
                attempts: []
            };
        });

        return {
            shuffledItems,
            sortedItems: [],
            requiredCount: this.items.filter(item => item.range).length,
            playing: false,
            completed: false
        };
    },
    computed: {
        textWithMarking() {
            let range = null;
            if (this.sortedItems.length) {
                range = this.sortedItems[this.sortedItems.length - 1].range;
            }
            return markTextRange(this.text, range);
        }
    },
    mounted() {
        this.fastForwardToInitialState();
        this.start();
    },
    methods: {
        start() {},
        fastForwardToInitialState() {
            this.state.forEach(itemId => {
                const unsortedItem = this.shuffledItems.find(item => item.id === itemId);
                unsortedItem.attempts.push({ matched: true });
                this.sortedItems.push({
                    id: itemId,
                    image: unsortedItem.image,
                    range: unsortedItem.range
                });
            });
        },
        pickItem(itemId) {
            const isCorrect = this.pick(itemId);
            let unsortedItem = this.shuffledItems.find(item => item.id === itemId);
            if (isCorrect) {
                soundService.play(correctSound);
                unsortedItem.attempts.push({ matched: true });
                this.sortedItems.push({
                    id: itemId,
                    image: unsortedItem.image,
                    range: unsortedItem.range
                });
                if (this.sortedItems.length === this.items.length) {
                    this.completed = true;
                }
            } else {
                unsortedItem.attempts.push({ matched: false });
            }

            if (this.state.length === this.requiredCount) {
                this.completed = true;
                setTimeout(() => {
                    this.$emit('done');
                }, 1000);
            } else {
                this.$emit('done');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.PickInSequence {
    position: relative;
    text-align: center;

    display: flex;
    flex-direction: column;
    color: rgba(white, 0.9);

    height: 100%;
    max-height: 40em;
}

.question {
    font-size: 140%;
    line-height: 1.5em;
}
.spacer {
    min-height: 2em;
    flex: 1;
}

.blockingLayer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.summaryContainer {
    padding: 1em;
    margin: 1em 0;
    background-color: white;
    border-radius: 0.5em;
    color: black;
    text-align: left;

    /deep/ .mark {
        background-color: yellow;
    }
    /deep/ .afterMark {
    }

    &:not(.show-tail) {
        /deep/ .afterMark {
            visibility: hidden;
            color: transparent; // Needed for hiding bullets on list items
        }
    }
}

.availableItems {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.Item {
    margin: 1em;
}

.sortedItems {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    min-height: calc(4em + 1em);
}
.SortedItem {
    margin: 0.5em;
    &:first-child {
        margin-left: 0;
    }

    $transitionDuration: 0.3s;
    &.list-enter-active,
    &.list-leave-active {
        transition: all $transitionDuration;
    }
    &.list-enter,
    &.list-leave-to {
        opacity: 0;
        transform: translateX(2em);
    }
}

@include media('>=mobile') {
    .summaryContainer {
        margin-top: 2em;
    }
}
@include media('<mobile') {
    .sortedItems {
        display: none;
    }
    .Item {
        margin: 0.5em;
    }
    .question {
        display: none;
    }
    .spacer {
        min-height: 0;
    }
    .summaryContainer {
        margin-top: 0;
    }
}
</style>
