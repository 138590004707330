<template>
  <div
    class="QuizHost"
    :class="{ showingSubsession: subsession }">
    <transition :name="componentTransition">
      <component
        :is="component"
        v-if="!done"
        :key="quizItemKey"
        :controller="controller"
        class="quizItem"
        @done="update()" />
    </transition>

    <transition
      name="slide-up"
      mode="out-in">
      <QuizHost
        v-if="subsession"
        :session="subsession"
        class="subsessionHost"
        @done="update()" />
    </transition>

    <div
      v-if="block"
      class="blockingLayer" />
  </div>
</template>

<script>
import MultipleChoiceInterface from './MultipleChoiceInterface';
import MultipleMatchInterface from './MultipleMatchInterface';
import MultiMultipleChoiceInterface from './MultiMultipleChoiceInterface';
import FallingMatchInterface from './FallingMatchInterface';
import PickInSequenceInterface from './PickInSequenceInterface';
import WhenQuestionWithNumberInputInterface from './WhenQuestionWithNumberInputInterface';

const componentMap = {
    multipleChoice: MultipleChoiceInterface,
    multipleMatch: MultipleMatchInterface,
    multiMultipleChoice: MultiMultipleChoiceInterface,
    fallingMatch: FallingMatchInterface,
    pickInSequence: PickInSequenceInterface,
    whenQuestionWithNumberInput: WhenQuestionWithNumberInputInterface
};

export default {
    name: 'QuizHost',
    props: {
        session: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            component: null,
            controller: null,
            subsession: null,
            unique: 0,
            quizItemKey: '',
            done: false,
            block: false
        };
    },
    created() {
        this.update();
    },
    mounted() {
        this.session.on('update', this.maybeEnd);
    },
    beforeDestroy() {
        this.session.off('update', this.maybeEnd);
    },
    computed: {
      componentTransition() {
        if (!this.done && this.controller.type === 'pickInSequence')
          return null;
        if (!this.done)
          return 'slideLeft';
        return 'fadeOut';
      },
    },
    methods: {
        maybeEnd() {
            // NOTE: Quick fix for blocking interaction as soon as quiz has failed
            if (this.session.initialLives && this.session.lives === 0) {
                this.block = true;
            }
        },
        async update() {
            const prevController = this.controller;
            this.controller = this.session.next();

            if (!this.controller) {
                this.done = true;
                await this.$nextTick(); // NOTE: Needed to let transition name take effect
                this.$emit('done');
                this.quizItemKey = 'done';
            } else {
              this.component = this.controller ? componentMap[this.controller.type] : null;
              this.subsession = (this.controller && this.controller.subsession) || null;

              if (this.controller !== prevController) {
                this.unique += 1;
              }

              this.quizItemKey = `${this.unique}-${this.controller.backInQueue}`;
            }

        }
    }
};
</script>

<style lang="scss" scoped>
.QuizHost {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 1em;
    margin-right: 1em;

    &.showingSubsession {
        & > .quizItem {
            transition: transform 0.5s, opacity 0.5s;
            opacity: 0;
            transform: translateY(-2em);
        }
    }
}
.subsessionHost {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    $transitionDuration: 0.5s;
    &.slide-up-enter-active,
    &.slide-up-leave-active {
        transition: opacity $transitionDuration, transform $transitionDuration;
    }

    &.slide-up-enter {
        transform: translate3d(0, 100%, 0);
    }

    &.slide-up-leave-to {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
}

.quizItem {
    width: 100%;
    max-width: 30em;
    position: absolute;

    $transitionDuration: 0.5s;
    $scale: 0.8;
    $distance: 100%;
    &.slideLeft-enter-active,
    &.slideLeft-leave-active {
        transition: transform $transitionDuration ease-out, opacity $transitionDuration;
    }
    &.slideLeft-enter {
        transform: translate3d(calc(#{$distance} + 3em), 0, 0) scale($scale);
        opacity: 0;
    }
    &.slideLeft-enter-to {
        transform: translate3d(0%, 0, 0);
    }
    &.slideLeft-leave-to {
        transform: translate3d(calc(-#{$distance} - 3em), 0, 0) scale($scale);
        opacity: 0;
    }

    &.fadeOut-leave-active {
        transition: transform $transitionDuration ease-out, opacity $transitionDuration;
    }
    &.fadeOut-leave-to {
        // transform: scale($scale);
        opacity: 0;
    }
}

.blockingLayer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
</style>
