export default class WhenQuestionWithNumberInputController {
    constructor({ question, subject, fasit, itemSource, itemId }, parent) {
        this.type = 'whenQuestionWithNumberInput';
        this.parent = parent;
        this.score = 0;
        this.progress = 0;
        this.totalCount = 1;
        this.correctCount = 0;
        this.failCount = 0;

        this.question = question;
        this.subject = subject;
        this.fasit = fasit;
        this.itemSource = itemSource;
        this.itemId = itemId;

        this._wrongAnswer = '';
    }

    getQuestion() {
        return this.question;
    }

    getFasit() {
        return this.fasit;
    }

    getSubject() {
        return this.subject;
    }

    submitAnswer(year) {
        const isCorrect = this.fasit === year;
        this.progress = 1;
        if (isCorrect) {
            this.score = 1;
            this.correctCount = 1;
        } else {
            this.score = 0;
            this.failCount += 1;
            this._wrongAnswer = year;
            // this.parent && this.parent.looseLife()
        }
        this.parent && this.parent.update();
        return isCorrect;
    }

    getSummary() {
        const completedItems = [];
        const failedItems = [];
        if (this.score === 1) {
            completedItems.push({
                id: this.itemId,
                source: this.itemSource
            });
        } else if (this.failCount && this.score < 1) {
            failedItems.push({
                id: this.itemId,
                source: this.itemSource,
                answer: this._wrongAnswer
            });
        }
        return {
            completedItems,
            failedItems
        };
    }
}
