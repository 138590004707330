<template>
  <div
    class="UnsortedItem"
    :class="[`state-${state}`]">
    <MnemonicImage
      class="mnemonicImage --global-clickable"
      :image="image"
      :style="{ transform: `rotate(${rotation}deg)` }"
      @click.native="handleClick" />
  </div>
</template>

<script>
import { TimelineLite, Power2 } from 'gsap';
import MnemonicImage from '@/components/MnemonicImage';

export default {
    components: { MnemonicImage },
    props: {
        image: {
            type: String
        },
        attempts: {
            type: Array
        }
    },
    data() {
        return {
            state: 'ready',
            rotation: Math.random() * 20 - 10
        };
    },
    watch: {
        attempts(value) {
            if (!value.length) {
                return;
            }
            let lastAttempt = value[value.length - 1];
            if (lastAttempt.matched) {
                this.onCorrect();
            } else {
                this.onWrong();
            }
        }
    },
    methods: {
        handleClick() {
            if (this.state === 'ready') {
                this.$emit('click');
            }
        },
        onCorrect() {
            this.state = 'correct';
            this.tl = new TimelineLite({
                onComplete: () => {}
            });
            this.tl.to(this.$el, 0.1, {
                scale: 1.1,
                ease: Power2.easeInOut
            });
            this.tl.to(this.$el, 0.3, {
                scale: 0.7,
                opacity: 0,
                ease: Power2.easeInOut
            });
        },
        onWrong() {
            this.state = 'wrong';
            this.tl = new TimelineLite({
                onComplete: () => {
                    this.state = 'ready';
                }
            });
            this.tl.to(this.$el, 0.1, {
                x: -7,
                ease: Power2.easeInOut
            });
            this.tl.to(this.$el, 0.1, {
                repeat: 4,
                x: 7,
                yoyo: true,
                ease: Power2.easeInOut
            });
            this.tl.to(this.$el, 0.1, {
                x: 0
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.UnsortedItem {
    &:not(.state-ready) {
        .mnemonicImage {
            cursor: default;
        }
    }

    &.state-correct {
    }
    &.state-wrong {
    }
}
.mnemonicImage {
    border-radius: 4px;
}
</style>
