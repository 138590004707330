export default function (text, range) {
    if (!range) {
        return ['<span class="afterMark">', text, '<span>'].join('');
    } else {
        let tailText = text.substr(range.start + range.length);
        if (tailText.indexOf('<li>') !== -1) {
            tailText = tailText.replace(/<li>/g, '<li class="afterMark">');
        } else {
            tailText = '<span class="afterMark">' + tailText + '</span>';
        }
        return [
            text.substr(0, range.start),
            '<span class="mark">',
            text.substr(range.start, range.length),
            '</span>',
            tailText
        ].join('');
    }
}
