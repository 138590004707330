<template>
  <div
    class="FallingItem"
    :class="[`state-${state}`]">
    {{ text }}
  </div>
</template>

<script>
import { TimelineLite, Power2, Power0 } from 'gsap';

export default {
    props: {
        text: {
            type: String
        },
        attempts: {
            type: Array
        },
        fallingDuration: {
            type: Number,
            default: 7000
        }
    },
    data() {
        return {
            state: 'falling'
            // fallingDuration: 7000
        };
    },
    watch: {
        attempts(value) {
            let lastAttempt = value[value.length - 1];
            if (lastAttempt.matched) {
                this.onCorrect();
            } else {
                this.onWrong();
            }
        }
    },
    mounted() {
        let elem = this.$el;
        const height = elem.clientHeight;
        const width = elem.clientWidth;
        const containerHeight = elem.parentElement.clientHeight;
        const containerWidth = elem.parentElement.clientWidth;

        if (width < containerWidth) {
            const remainingSpace = containerWidth - width;
            const randomLeftPos = Math.round(Math.random() * remainingSpace);
            elem.style.left = `${randomLeftPos}px`;
        }
        this.fallingTimeline = new TimelineLite({
            onComplete: () => {
                this.onTimeout();
            }
        });
        this.fallingTimeline.to(
            elem,
            this.fallingDuration / 1000,
            {
                ease: Power0.easeNone,
                y: containerHeight - height
            },
            0
        );
    },
    beforeDestroy() {
        if (this.fallingTimeline) {
            this.fallingTimeline.kill();
        }
        if (this.tl) {
            this.tl.kill();
        }
    },
    methods: {
        onCorrect() {
            this.fallingTimeline.pause();
            this.state = 'correct';
            this.tl = new TimelineLite({
                onComplete: () => {}
            });
            this.tl.to(this.$el, 0.1, {
                scale: 1.1,
                ease: Power2.easeInOut
            });
            this.tl.to(this.$el, 0.3, {
                scale: 0.7,
                opacity: 0,
                ease: Power2.easeInOut
            });
        },
        onWrong() {
            this.fallingTimeline.pause();
            this.state = 'wrong';
            this.tl = new TimelineLite({
                onComplete: () => {
                    this.state = 'ready';
                }
            });
            this.tl.to(this.$el, 0.1, {
                x: -7,
                ease: Power2.easeInOut
            });
            this.tl.to(this.$el, 0.1, {
                repeat: 4,
                x: 7,
                yoyo: true,
                ease: Power2.easeInOut
            });
            this.tl.to(this.$el, 0.1, {
                x: 0
            });
            this.tl.to(
                this.$el,
                0.5,
                {
                    opacity: 0,
                    ease: Power2.easeInOut
                },
                '-=0.5'
            );
        },
        onTimeout() {
            this.fallingTimeline.pause();
            this.state = 'timeout';
            this.$emit('timeout');

            this.tl = new TimelineLite({
                onComplete: () => {}
            });
            this.tl.to(this.$el, 0.5, {
                opacity: 0,
                ease: Power2.easeInOut
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.FallingItem {
    background-color: white;
    padding: 0.5em 1em;
    border-radius: 2em;
    color: black;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;

    &.state-correct {
        background-color: green;
        color: white;
    }
    &.state-wrong {
        background-color: red;
        color: white;
    }
    &.state-timeout {
        // background-color: orange;
        background-color: red;
        color: white;
    }
}
</style>
