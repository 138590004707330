<template>
  <div class="FallingMatch">
    <div class="question">
      {{ question }}
    </div>
    <div class="spacer" />

    <div class="fallingTextCanvas">
      <FallingItem
        v-for="(item, i) of fallingItems"
        :key="i"
        class="FallingItem"
        :text="item.label"
        :attempts="item.attempts"
        :falling-duration="fallingDuration"
        @timeout="onFallingItemTimeout(item.id)" />
    </div>
    <div class="divider" />
    <div class="possibleMatches">
      <ChoiceItem
        v-for="item of imageItems"
        :key="item.pairId"
        class="Choice"
        :image="item.image"
        :attempts="item.attempts"
        @click="pickMatch(item.id)" />
    </div>

    <!-- <div
      v-if="selectedIndex !== -1"
      class="blockingLayer"
    /> -->
  </div>
</template>

<script>
import shuffle from 'lodash-es/shuffle';
import soundService from '@/services/sound-service';
import correctSound from '@/assets/sounds/correct-answer.mp3';

import FallingItem from './FallingItem';
import ChoiceItem from './ChoiceItem';

export default {
    components: { FallingItem, ChoiceItem },
    props: {
        question: {
            type: String,
            required: true
        },
        targetItems: {
            type: Array
        },
        shelfItems: {
            type: Array
        },
        fallingDuration: {
            type: Number,
            default: 7000
        },
        shuffle: {
            type: Boolean,
            default: true
        },
        discardOnFail: {
            type: Boolean,
            default: false
        },
        maxItemsOnShelf: {
            type: Number,
            default: 100 // 'No limit'
        },
        match: {
            type: Function
        }
        // remainingLivesInitialValue: {
        //   type: Number,
        //   default: 3
        // }
    },
    data() {
        return {
            // totalLives: 3,
            // remainingLives: 3,
            textItems: [],
            imageItems: [],
            fallingItems: [],
            queue: [],
            playing: false
        };
    },
    mounted() {
        this.start();
    },
    methods: {
        start() {
            let textItems = this.targetItems.map(targetItem => {
                return {
                    label: targetItem.label,
                    id: targetItem.id,
                    attempts: []
                };
            });
            let imageItems = this.shelfItems.map(shelfItem => {
                return {
                    image: shelfItem.image,
                    id: shelfItem.id,
                    attempts: []
                };
            });

            let queue = this.targetItems.map(targetItem => targetItem.id);
            //
            // // this.remainingLives = this.remainingLivesInitialValue // 3
            this.textItems = textItems;
            this.fallingItems = [];
            if (this.shuffle) {
                this.imageItems = shuffle(imageItems);
                this.queue = shuffle(queue);
            } else {
                this.imageItems = imageItems.slice();
                // this.imageItems = shuffle(imageItems)
                this.queue = queue.slice();
            }
            //
            this.playing = true;
            this.releaseNext();
        },
        releaseNext() {
            if (!this.playing) {
                return;
            }
            if (this.queue.length > 0) {
                let itemId = this.queue.shift();

                this.fallingItems.push({
                    id: itemId,
                    label: this.textItems.find(x => x.id === itemId).label,
                    attempts: []
                });
            } else {
                this.handleGameCompleted();
            }
        },
        pushBackOnQueue(itemId) {
            if (this.discardOnFail) {
                return;
            }
            this.queue.push(itemId);
        },
        getClosestFallingItem() {
            // NOTE: Since all items fall with the same speed...
            return this.fallingItems[this.fallingItems.length - 1];
        },
        pickMatch(pickedItemId) {
            if (!this.playing) {
                return;
            }
            let fallingItem = this.getClosestFallingItem();
            let pickedItem = this.imageItems.find(item => item.id === pickedItemId);

            const didMatch = this.match(fallingItem.id, pickedItem.id);

            if (didMatch) {
                pickedItem.attempts.push({ matched: true });
                fallingItem.attempts.push({ matched: true });
                soundService.play(correctSound);
            } else {
                pickedItem.attempts.push({ matched: false });
                fallingItem.attempts.push({ matched: false });
                // this.looseLife()
                this.pushBackOnQueue(fallingItem.id);
            }

            if (this.queue.length === 0) {
                setTimeout(() => {
                    this.$emit('done');
                }, 1000);
            } else {
                this.$emit('done');
            }
            // this.$emit('done')
            this.releaseNext();
        },
        onFallingItemTimeout(itemId) {
            this.match(itemId, null);
            this.pushBackOnQueue(itemId);
            this.$emit('done');
            this.releaseNext();
        },
        handleGameCompleted() {
            this.playing = false;
            // this.$emit('done')
            // setTimeout(() => {
            //   this.$emit('success', { // NOTE: currently needed for JourneyMemogamePopup
            //     remainingLives: this.remainingLives
            //   })
            //   this.$parent.$emit('success')
            // }, 1000)
        }
        // handleGameOver () {
        //   this.playing = false
        //   setTimeout(() => {
        //     this.$emit('gameover') // NOTE: currently needed for JourneyMemogamePopup
        //     this.$parent.$emit('gameover')
        //   }, 1000)
        // }
    }
};
</script>

<style lang="scss" scoped>
.FallingMatch {
    position: relative;
    text-align: center;

    display: flex;
    flex-direction: column;
    color: rgba(white, 0.9);

    height: 100%;
    max-height: 40em;
}

.fallingTextCanvas {
    position: relative;
    height: 30em;
}
.divider {
    height: 1px;
    background-color: rgba(white, 0.1);
    margin-top: 0.5em;
}
.possibleMatches {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1em;
    flex-shrink: 0;
}
.Choice {
    margin: 0.5em;
}

.question {
    font-size: 140%;
    line-height: 1.5em;
}
.spacer {
    min-height: 2em;
    flex: 1;
}

.blockingLayer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

@include media('<mobile') {
    .question {
        display: none;
    }
    .spacer {
        min-height: 0;
    }
}
</style>
