<template>
  <div class="WhenQuestionWithNumberInput">
    <div class="question">
      {{ question }}
    </div>
    <div class="textBox">
      <div class="text">
        {{ subject }}
      </div>
    </div>
    <div class="spacer" />

    <div
      ref="dateContainer"
      class="dateContainer">
      <Date
        class="date"
        :year="number"
        :focus="focus"
        @request-change="(attr, value) => (number = value)"
        @request-focus="(attr, index) => setFocus(1, attr, index)" />
      <span
        v-if="beforeChrist"
        class="beforeChrist">B.C</span>
    </div>
    <div class="spacer" />

    <DigitPad
      class="digitPad"
      @click-digit="setDigit" />

    <div
      v-if="focusIndex === -1"
      class="blockingLayer" />
  </div>
</template>

<script>
import { TimelineMax, Power2 } from 'gsap';
import soundService from '@/services/sound-service';
import correctSound from '@/assets/sounds/correct-answer.mp3';

import DigitPad from '@/modules/games-shared/components/DigitPad';
import Date from '@/modules/game-timemachine/components/Date';

export default {
    components: { DigitPad, Date },
    props: {
        question: {
            type: String,
            required: true
        },
        subject: {
            type: String,
            required: true
        },
        fasit: {
            type: Number,
            required: true
        },
        submitAnswer: {
            type: Function
        }
    },
    data() {
        return {
            beforeChrist: this.fasit < 0,
            number: '------'.substr(0, `${Math.abs(this.fasit)}`.length),
            focusIndex: 0
        };
    },
    computed: {
        focus() {
            return { year: this.focusIndex };
        }
    },
    methods: {
        async setDigit(digit) {
            if (this.focusIndex === -1) {
                return;
            }
            if (digit === '<') {
                return this.moveFocusLeft();
            } else if (digit === '>') {
                return this.moveFocusRight();
            }

            let digits = this.number.split('');
            if (digit === 'x') {
                digit = '-';
            }
            digits[this.focusIndex] = digit;
            this.number = digits.join('');

            if (digit === '-') {
                return;
            }

            const allFilled = this.number.indexOf('-') === -1;
            if (allFilled) {
                this.focusIndex = -1;
                const correct = this.submitAnswer(parseInt(this.number, 10) * (this.beforeChrist ? -1 : 1));
                await this.playFeedback(correct);
                this.$emit('done');
            } else {
                this.moveFocusRight();
            }
        },
        setFocus(rowIndex, attr2, index) {
            this.focusIndex = index;
        },
        moveFocusLeft() {
            if (this.focusIndex <= 0) {
                return;
            }
            this.focusIndex -= 1;
        },
        moveFocusRight() {
            if (this.focusIndex >= this.number.length - 1) {
                return;
            }
            this.focusIndex += 1;
        },
        async playFeedback(correct) {
            if (correct) {
                await this.feedbackOnCorrect();
            } else {
                await this.feedbackOnWrong();
            }
        },

        feedbackOnCorrect() {
            soundService.play(correctSound);
            return new Promise(resolve => {
                let elementsToAnimate = this.$refs.dateContainer;
                let tl = (this.tl = new TimelineMax({ onComplete: () => resolve() }));
                tl.to(elementsToAnimate, 0.3, {
                    scale: 1.1,
                    ease: Power2.easeInOut
                });
                tl.to(elementsToAnimate, 0.3, {
                    scale: 1,
                    ease: Power2.easeInOut
                });
            });
        },
        feedbackOnWrong() {
            return new Promise(resolve => {
                let shakeSpeed = 0.1;
                let elementsToShake = this.$refs.dateContainer;

                let tl = (this.tl = new TimelineMax({ onComplete: () => resolve() }));
                tl.to(elementsToShake, shakeSpeed, {
                    x: -7,
                    ease: Power2.easeInOut
                });
                tl.to(elementsToShake, shakeSpeed, {
                    repeat: 4,
                    x: 5,
                    yoyo: true,
                    ease: Power2.easeInOut
                });
                tl.to(elementsToShake, shakeSpeed, {
                    x: 0
                });
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.WhenQuestionWithNumberInput {
    position: relative;
    text-align: center;

    display: flex;
    flex-direction: column;
    color: rgba(white, 0.9);
}
.question {
    font-size: 120%;
    line-height: 1.5em;
}
.textBox {
    margin-top: 1em;
    padding: 1em 1em;
    background-color: white;
    border-radius: 0.5em;
    align-self: stretch;

    box-shadow: 0 1em 1em rgba(black, 0.2);
}
.text {
    line-height: 1.5em;
    color: mix(black, white, 80%);
    font-size: 120%;
}
.dateContainer {
    display: flex;
    align-items: center;
    align-self: center;
}
.beforeChrist {
    margin-left: 1em;
    font-size: 150%;
    color: rgba(white, 0.7);
}
.spacer {
    min-height: 2em;
    flex: 1;
}

.digitPad {
    max-width: 20em;
    width: 100%;
    align-self: center;
}

.blockingLayer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
</style>
