import WhenQuestionWithNumberInput from './WhenQuestionWithNumberInput';

export default {
    data() {
        return {
            state: this.controller.state
        };
    },
    render: function (h) {
        return h(WhenQuestionWithNumberInput, {
            props: {
                question: this.controller.getQuestion(),
                subject: this.controller.getSubject(),
                fasit: this.controller.getFasit(),
                submitAnswer: this.controller.submitAnswer.bind(this.controller),
                state: this.state
            },
            on: {
                // done: this.controller.done.bind(this.controller)
                done: () => {
                    // console.log('DONE')
                    this.$emit('done');
                }
            }
        });
    },
    props: {
        controller: {
            type: Object,
            required: true
        }
    }
};
