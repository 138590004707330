import MultipleChoicePure from './MultipleChoicePure';

export default {
    render: function (h) {
        return h(MultipleChoicePure, {
            props: {
                question: this.controller.getQuestion(),
                fasit: this.controller.getFasit(),
                choices: this.controller.getChoices(),
                select: this.controller.select.bind(this.controller)
            },
            on: {
                // done: () => this.controller.done()
                done: () => {
                    // console.log('DONE')
                    this.$emit('done');
                }
            }
        });
    },
    props: {
        controller: {
            type: Object,
            required: true
        }
    }
};
