import FallingMatch from './FallingMatch';

export default {
    data() {
        return {
            state: this.controller.state
        };
    },
    computed: {
        shouldShuffle() {
            return !this.$store.state.moduleApp.debugSettings.useQuizCheatMode;
        }
    },
    render: function (h) {
        return h(FallingMatch, {
            props: {
                question: this.controller.getQuestion(),
                targetItems: this.controller.getTargetItems(),
                shuffle: this.shouldShuffle,
                shelfItems: this.controller.getShelfItems(),
                match: this.controller.match.bind(this.controller),
                state: this.state
            },
            on: {
                // done: this.controller.done.bind(this.controller)
                done: () => {
                    // console.log('DONE')
                    this.$emit('done');
                }
            }
        });
    },
    props: {
        controller: {
            type: Object,
            required: true
        }
    }
};
