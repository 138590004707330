<template>
  <div class="Quiz">
    <div v-if="!showQuiz" class="countdownContainer">
      <div v-if="!showCountdown" class="startingQuizTitleContainer">
        <p class="startingQuizTitle">{{ $t('startingQuiz') }}</p>
        <p>{{ $t('youWill') }}</p>
      </div>
      <Countdown v-if="showCountdown" @countdownFinished="startQuiz()" />
    </div>

    <div v-if="showQuiz" class="backButton --global-clickable" @click="exit">
      <svgicon icon="close" />
    </div>
    <ProgressBar v-if="showQuiz" class="progressBar" :progress="progress" />

    <div v-if="showQuiz" class="quizContainer">
      <StepBackground :image="course.bannerImage" />

      <QuizHost v-if="session" :session="session" />
    </div>
  </div>
</template>

<translations>

  startingQuiz: Starting team quiz
  startingQuiz_no: Team quizen starter

  youWill: You will be redirected to the quiz
  youWill_no: Du blir videresendt til quizen

</translations>

<script>
import { mapState } from 'vuex'

import ThemeMixin from '@/modules/course/theme-mixin'
import ProgressBar from '@/components/ProgressBar'
import QuizHost from '@/modules/quiz/components/QuizHost'
import QuizSession from '@/modules/quiz/quiz-session'
import StepBackground from '@/modules/course/components/StepBackground'
import quizHelper from '@/modules/course/quiz-helper'
import Countdown from './QuizCountdown'
// import soundService from '@/services/sound-service';
import bgMusic from '../data/audio/quizMusic.mp3'

export default {
  components: { StepBackground, ProgressBar, QuizHost, Countdown },

  mixins: [ThemeMixin],

  props: {
    gameId: {
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      remainingLives: -1,
      progress: 0,
      session: null,
      timeUsed: 0,
      showQuiz: false,
      showCountdown: false,
      timeIntervalId: null,
      refreshId: null,
      sounds: [bgMusic],
    }
  },

  computed: {
    ...mapState({
      games: state => state.moduleMemoransier.games,
    }),

    game() {
      return this.games.find(game => game.id === this.gameId)
    },

    gameOptions() {
      return this.game.options
    },

    stepGroupId() {
      return this.game.options.stepGroupId
    },

    stepGroup() {
      if(!this.stepGroupId){
        return null
      }
      return this.$store.state.moduleCourse.stepGroups[this.stepGroupId]
    },

    course() {
      return this.$store.state.moduleCourse.courses[this.gameOptions.courseId]
    },
  },

  async mounted() {
    // soundService.preload(this.sounds);
    await this.refresh()
    this.loading = false

    const quizState = this.stepGroup ? this.$store.getters['moduleCourse/getStepGroupQuizState'](this.stepGroup.id) : null

    const courseId = this.stepGroup?.courseId ?? this.course.id
    await this.$store.dispatch('moduleCourse/loadCourseFull', { courseId })

    const dataProvider = await quizHelper.createQuestionProvider(this.stepGroup?.quiz ?? {generator:'standardCourseQuiz'}, {
      courseId: this.gameOptions.courseId,
      stepGroupId: this.stepGroupId,
      quizState,
    })

    this.session = new QuizSession(dataProvider, { lives: Number.POSITIVE_INFINITY, requeue: false })
    this.session.on('update', this.onUpdate)
    this.session.on('end', this.onEnd)
    this.onUpdate()
    this.startTimer()
  },

  beforeDestroy() {
    clearInterval(this.timeIntervalId)
    clearTimeout(this.refreshId)
    // soundService.stop(bgMusic, 500);
  },

  methods: {
    startQuiz() {
      this.showQuiz = true
      // soundService.play(bgMusic, { volume: 0.2, loop: true }, 'background');
    },

    startTimer() {
      setTimeout(() => {
        this.showCountdown = true
      }, 1500)
      this.timeIntervalId = setInterval(() => {
        this.timeUsed += 1
      }, 1000)
    },

    async refresh() {
      clearTimeout(this.refreshId)

      await this.$store.dispatch('moduleMemoransier/refresh')
      const game = this.$store.state.moduleMemoransier.games.find(g => g.id === this.gameId)
      const isGameFinished = game && game.leaderboardReveal

      if (isGameFinished) {
        this.exit()
        return
      }

      this.refreshId = setTimeout(() => this.refresh(), 1000)
    },

    onUpdate() {
      this.progress = this.session.correctCount / this.session.totalCount
      this.remainingLives = this.session.lives
      this.updatePlayer(false)
    },
    onEnd() {
      this.updatePlayer(true)
      if(this.stepGroupId){
        this.$store.dispatch('moduleCourse/registerStepGroupQuizResult', {
          stepGroupId: this.stepGroupId,
          summary: this.session.getSummary(),
        })
      }
      this.exit()
    },
    updatePlayer(completed) {
      this.$store.dispatch('moduleMemoransier/updatePlayer', {
        gameId: this.gameId,
        correct: this.session.correctCount,
        completed,
        time: { seconds: this.timeUsed },
      })
    },

    exit() {
      this.$router.push({
        name: 'MemoransierPodium',
        props: {
          gameId: this.gameId,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.Quiz {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(180deg, #0a1335, #1e1456 93.87%);
}

.countdownContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .startingQuizTitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #a4b1cd;
    visibility: visible;
    transition: visibility 1s ease-in;
    font-size: 1.2em;

    &.show {
      visibility: hidden;
    }

    .startingQuizTitle {
      font-size: 1.5em;
      margin: 0em;
      color: white;
    }
    p {
      margin-top: 0.5em;
    }
  }
}

.progressBar {
  position: absolute;
  justify-self: flex-start;
  top: 0;
  left: 0;
  z-index: 1000;
}
.backButton {
  padding: 0 1em;
  height: $topBarHeight;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  .svg-icon {
    $size: 0.8em;
    width: $size;
    height: $size;
    fill: rgba(white, 0.9);
  }
}
.spacer {
  flex: 1;
}

.Lives {
  margin-left: 2em;
}

.countdown {
  margin-left: 1em;
}

.QuizHost {
  flex: 1;
}

@include media('>=mobile') {
  .backButton {
    right: 1em;
  }
}
@include media('<mobile') {
  .Lives {
    margin-left: 1em;
  }
}
</style>
