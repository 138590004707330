<template>
  <div
    class="RecreateMemo"
    :class="{ completed }">
    <SourceTargetInteraction
      :target-ids="targetIds"
      :source-ids="sourceIds"
      :check-match="checkMatch"
      :initial-state="initialState"
      source-selector=".ShelfItem"
      target-selector=".TargetItem">
      <template slot-scope="{ sourceItems: sItems, targetItems: tItems }">
        <!-- <div class="instruction">
          {{ $t('instruction') }}
        </div> -->
        <div class="question">
          {{ question }}
        </div>
        <div class="spacer" />

        <div class="card">
          <div
            ref="summaryContainer"
            class="summaryContainer"
            :class="{ 'show-tail': completed }"
            v-html="textWithMarking" />
          <div class="targetContainer">
            <HorizontalScrollContainer ref="horizontalScrollContainer">
              <div class="availableItems">
                <TargetItem
                  v-for="(targetItem, i) of tItems"
                  :key="targetItem.id"
                  :data-id="targetItem.id"
                  :data="targetItemsMap[targetItem.id]"
                  :other="sourceItemsMap[targetItem.id]"
                  :matched="i < state.length"
                  :disabled="i > state.length"
                  v-bind="targetItem"
                  class="TargetItem">
                  {{ targetItemsMap[targetItem.id].label }}
                </TargetItem>
              </div>
            </HorizontalScrollContainer>
          </div>
        </div>

        <div class="spacer" />

        <Shelf
          class="shelf"
          :items="sItems"
          :mobile-compact="false">
          <template
            slot="shelfItem"
            slot-scope="sourceItem">
            <ShelfItemWithImage
              class="ShelfItem"
              :state="sourceItem"
              v-bind="sourceItemsMap[sourceItem.id]"
              :data-id="sourceItem.id" />
          </template>
        </Shelf>
      </template>
      <template
        slot="dragItem"
        slot-scope="props">
        <ShelfItemWithImage
          class="dragItem"
          :state="{}"
          v-bind="sourceItemsMap[props.id]" />
      </template>
    </SourceTargetInteraction>
  </div>
</template>

<translations>
  instruction: 'Drag n drop to create the Memo story'
</translations>

<script>
import soundService from '@/services/sound-service';
import correctSound from '@/assets/sounds/correct-answer.mp3';
import markTextRange from './mark-text-range';
import scrollIntoView from '@/modules/memo/scrollIntoView';

import SourceTargetInteraction from '@/modules/games-shared/components/SourceTargetInteraction';
import HorizontalScrollContainer from '@/components/HorizontalScrollContainer';
import Shelf from '@/modules/games-shared/components/Shelf';
import ShelfItemWithImage from '@/modules/games-shared/components/ShelfItemWithImage';
import TargetItem from '../MatchTargetItem';

export default {
    components: { SourceTargetInteraction, Shelf, ShelfItemWithImage, HorizontalScrollContainer, TargetItem },
    props: {
        question: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        items: {
            type: Array
        },
        state: {
            type: Array
        },
        pick: {
            type: Function
        }
    },
    data() {
        const targetItemsMap = {};
        const sourceItemsMap = {};
        const sourceIds = [];

        const targetItems = [];

        this.items.forEach((item, i) => {
            if (item.range) {
                const targetItem = {
                    id: item.id,
                    image: item.image,
                    range: item.range
                };
                targetItemsMap[item.id] = targetItem;
                targetItems.push(targetItem);
            }
            sourceItemsMap[item.id] = {
                image: item.image,
                id: item.id
            };
            sourceIds.push(item.id);
        });

        targetItems.sort((a, b) => {
            return a.range.start - b.range.start;
        });
        targetItems.forEach((item, i) => {
            item.label = `${i + 1}`;
        });
        const targetIds = targetItems.map(item => item.id);

        return {
            playing: false,
            completed: false,

            requiredCount: targetIds.length,
            targetItemsMap,
            targetIds,
            sourceItemsMap,
            sourceIds
        };
    },
    computed: {
        textWithMarking() {
            let range = null;
            if (this.state.length) {
                const latestItemId = this.state[this.state.length - 1];
                range = this.targetItemsMap[latestItemId].range;
            }
            return markTextRange(this.text, range);
        },
        activeTargetIndex() {
            return this.state.length;
        }
    },
    watch: {
        activeTargetIndex: function (val) {
            this.scrollIntoView();
            this.$nextTick(() => {
                this.updateSummaryScroll();
            });
        }
    },
    created() {
        if (this.state.length) {
            this.initialState = {};
            this.state.forEach(itemId => {
                this.initialState[itemId] = itemId;
            });
        } else {
            this.initialState = null;
        }
    },
    mounted() {
        this.start();
    },
    methods: {
        start() {},
        checkMatch(a, b) {
            return this.pickItem(a);
        },
        pickItem(itemId) {
            const isCorrect = this.pick(itemId);

            if (isCorrect) {
                soundService.play(correctSound);
            }

            if (this.state.length === this.requiredCount) {
                this.completed = true;
                setTimeout(() => {
                    this.$emit('done');
                }, 1000);
            } else {
                this.$emit('done');
            }
            return isCorrect;
        },
        scrollIntoView() {
            const elem = this.$el.querySelector(`.TargetItem:nth-child(${this.activeTargetIndex + 1})`);
            if (elem) {
                this.$refs.horizontalScrollContainer.scrollElementIntoView(elem, true);
            }
        },
        updateSummaryScroll() {
            scrollIntoView(this.$refs.summaryContainer.querySelector('.mark'), this.$refs.summaryContainer);
        }
    }
};
</script>

<style lang="scss" scoped>
$borderRadius: 1em;

.RecreateMemo {
    position: relative;
    text-align: center;

    display: flex;
    flex-direction: column;
    color: rgba(white, 0.9);

    height: 100%;
    max-height: 40em;

    &.completed {
        .shelf {
            pointer-events: none; // Disable interaction
        }
    }
}

.SourceTargetInteraction {
    height: 100%;
}

.question {
    font-size: 140%;
    line-height: 1.5em;
}
.spacer {
    min-height: 2em;
    flex: 1;
}

.card {
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.summaryContainer {
    padding: 1em;
    background-color: white;
    color: black;
    text-align: left;
    overflow: auto;
    border-radius: $borderRadius $borderRadius 0 0;

    /deep/ .mark {
        background-color: yellow;
    }
    /deep/ .afterMark {
    }

    &:not(.show-tail) {
        /deep/ .afterMark {
            opacity: 0;
        }
    }
}

.targetContainer {
    flex-shrink: 0;
    background-color: black;
    border-radius: 0 0 $borderRadius $borderRadius;
}

.TargetItem {
    flex-shrink: 0;
    &:not(:first-child) {
        margin-left: 1em;
    }

    border: 1px dashed rgba(white, 0.5);
    /deep/ .placeholderLabel {
        font-size: 140%;
        color: rgba(white, 0.6);
    }
    &:not(.disabled) {
        /deep/ .placeholderLabel {
            color: rgba(white, 0.4);
        }
    }
}

.shelf /deep/ .carousel {
    background-color: rgba(white, 0.12);
}

.HorizontalScrollContainer {
    padding: 2em 0;
}
.availableItems {
    align-items: center;

    display: inline-flex;

    margin-left: 1em;
    margin-right: 1em;
}
.Item {
    margin: 1em;
}

.dragItem {
    transform: scale(1.3);
}

@include media('<mobile') {
    .question {
        display: none;
    }
}
</style>
