import shuffle from 'lodash-es/shuffle';
import store from '@/store';

export default class MultiMultipleChoiceController {
    constructor({ question, choices }, parent) {
        this.type = 'multiMultipleChoice';
        this.parent = parent;
        this.score = 0;
        this.progress = 0;
        this.correctCount = 0;
        this.failCount = 0;
        this.question = question;

        this.choices = choices.map((x, i) => {
            const choice = Object.assign({ id: `choice-${i + 1}` }, x);
            return choice;
        });
        this.fasit = [];
        this.choices.forEach(choice => {
            if (choice.isCorrect) {
                this.fasit.push(choice.id);
                delete choice.isCorrect;
            }
        });
        this.state = {};
        this.choices.forEach(x => {
            this.state[x.id] = false;
        });

        const shouldShuffle = !store.state.moduleApp.debugSettings.useQuizCheatMode;
        if (shouldShuffle) {
            this.choices = shuffle(this.choices);
        }
        this.totalCount = this.fasit.length;
    }

    getQuestion() {
        return this.question;
    }

    getFasit() {
        return this.fasit;
    }

    getChoices() {
        return this.choices;
    }

    select(choiceId) {
        const isCorrect = this.fasit.indexOf(choiceId) !== -1;
        if (isCorrect) {
            if (!this.state[choiceId]) {
                this.correctCount += 1;
                this.state[choiceId] = true;
            }
            this.score = this.correctCount / this.totalCount;
            this.progress = this.correctCount / this.totalCount;
        } else {
            // this.score = 0
            this.failCount += 1;
            // this.parent && this.parent.looseLife()
        }
        // this.parent && this.parent.update()
        this.broadcastUpdate();
        return isCorrect;
    }

    getIdFromText(text) {
        // Used for unit-tests
        return this.choices.find(x => x.text === text).id;
    }

    broadcastUpdate() {
        this.parent && this.parent.update();
        this.extraCallbackOnUpdate && this.extraCallbackOnUpdate();
    }
}
