import PickInSequence from './PickInSequence';
import RecreateMemo from './RecreateMemo';

export default {
    data() {
        return {
            state: this.controller.state
        };
    },
    render: function (h) {
        return h(this.controller.renderer === 'RecreateMemo' ? RecreateMemo : PickInSequence, {
            props: {
                question: this.controller.getQuestion(),
                text: this.controller.getText(),
                items: this.controller.getItems(),
                pick: this.controller.pick.bind(this.controller),
                state: this.state
            },
            on: {
                // done: this.controller.done.bind(this.controller)
                done: () => {
                    // console.log('DONE')
                    this.$emit('done');
                }
            }
        });
    },
    props: {
        controller: {
            type: Object,
            required: true
        }
    }
};
