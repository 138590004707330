<template>
  <div class="MultipleChoicePure">
    <div class="question">
      {{ question }}
    </div>
    <div class="spacer" />

    <div
      :class="{ useGridLayout }"
      class="choices">
      <ChoiceItem
        v-for="(choice, i) of choices"
        :key="i"
        ref="choiceItems"
        v-bind="choice"
        :state="{ selected: i === selectedIndex, correct: choice.id === fasit }"
        class="Option"
        @click="pickedChoice(i)" />
    </div>

    <div
      v-if="selectedIndex !== -1"
      class="blockingLayer" />
  </div>
</template>

<script>
import { TimelineMax, Power2 } from 'gsap';
import soundService from '@/services/sound-service';
import correctSound from '@/assets/sounds/correct-answer.mp3';
import wrongSound from '@/assets/sounds/wrong-answer.mp3';

import ChoiceItem from './ChoiceItem';

export default {
    components: { ChoiceItem },
    props: {
        question: {
            type: String,
            required: true
        },
        fasit: {
            type: String
        },
        choices: {
            type: Array,
            required: true
        },
        select: {
            type: Function
        }
    },
    data() {
        return {
            selectedIndex: -1
        };
    },

    created() {
        this.useGridLayout = this.choices.every(x => x && x.image);
    },
    methods: {
        async pickedChoice(index) {
            this.selectedIndex = index;

            const correct = this.select(this.choices[this.selectedIndex].id);
            await this.playFeedback(correct);
            this.$emit('done');

            // this.playFeedback(correct)
            // setTimeout(() => {
            //   this.$emit('done')
            // }, 500)
        },
        async playFeedback(correct) {
            if (correct) {
                await this.feedbackOnCorrect();
            } else {
                await this.feedbackOnWrong();
            }
        },
        feedbackOnTimeout() {
            this.feedback = 'timeout';
            return new Promise(resolve => {
                let correctIndex = this.choices.indexOf(this.controller.getCorrectAnswer());
                let wrongElems = this.$el.querySelectorAll(`.Choice:not(:nth-child(${correctIndex + 1}))`);

                let tl = (this.tl = new TimelineMax({ onComplete: () => resolve() }));
                tl.to(wrongElems, 0.5, { opacity: 0 });
                tl.to(this.$el, 1.5, {});
            });
        },

        feedbackOnCorrect(scoreEvents) {
            this.feedback = 'correct';
            soundService.play(correctSound);

            return new Promise(resolve => {
                const otherElements = this.$refs.choiceItems.map(x => x.$el);
                const selectedChoiceElement = otherElements.splice(this.selectedIndex, 1);

                let tl = (this.tl = new TimelineMax({ onComplete: () => resolve() }));
                tl.to(selectedChoiceElement, 0.2, {
                    scale: 1.1
                });
                tl.to(selectedChoiceElement, 0.2, {
                    ease: Power2.easeInOut,
                    scale: 1
                });
                tl.to(selectedChoiceElement, 1, {});
                tl.to(otherElements, 0.5, { opacity: 0 }, 0);
            });
        },

        feedbackOnWrong() {
            this.feedback = 'wrong';
            soundService.play(wrongSound);

            return new Promise(resolve => {
                const shakeSpeed = 0.1;

                const allElements = this.$refs.choiceItems.map(x => x.$el);
                const selectedIndex = this.selectedIndex; // this.choices.indexOf(this.pickedChoice)
                const correctIndex = this.choices.findIndex(x => x.id === this.fasit);

                const selectedChoiceElement = allElements[selectedIndex];
                const wrongElements = allElements.filter((x, i) => i !== selectedIndex && i !== correctIndex);

                let tl = (this.tl = new TimelineMax({ onComplete: () => resolve() }));
                tl.to(selectedChoiceElement, shakeSpeed, {
                    x: -7,
                    ease: Power2.easeInOut
                });
                tl.to(selectedChoiceElement, shakeSpeed, {
                    repeat: 4,
                    x: 5,
                    yoyo: true,
                    ease: Power2.easeInOut
                });
                tl.to(selectedChoiceElement, shakeSpeed, {
                    x: 0
                });
                tl.to(selectedChoiceElement, 2, {});
                tl.to(wrongElements, 0.5, { opacity: 0 }, 1);
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.MultipleChoicePure {
    position: relative;
    text-align: center;

    display: flex;
    flex-direction: column;
    color: rgba(white, 0.9);
}
.question {
    font-size: 140%;
    line-height: 1.5em;
}
.spacer {
    min-height: 2em;
    flex: 1;
}
.choices {
    &.useGridLayout {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &:not(.useGridLayout) {
        .Option {
            &:not(:last-child) {
                margin-bottom: 1em;
            }
        }
    }
}

.blockingLayer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
</style>
